<template>
    <div class="personal-msg page-container flex-column">
        <div class="personal-msg-head flex-row">
            <h2>我的消息</h2>
            <div>(共{{pagination.total}}条，其中<span>未读消息</span> {{unreadCount}} 条)</div>
        </div>
        <div class="personal-msg-option mb10">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="checkAllChangeHandle"></el-checkbox>
            <el-button class="ml10" size="mini" type="primary" @click="deleteHandle">删除</el-button>
            <el-button class="ml10" size="mini" @click="makAllReadHandle">全部标记为已读</el-button>
        </div>
        <div class="personal-msg-table" ref="table" v-scrollbar v-empty="noData">
            <el-checkbox-group v-model="checkedList" @change="checkedChangeHandle">
                <div v-for="(item, index) in tableList" :key="item.id" class="table-row flex-row">
                    <el-checkbox :label="item.id"></el-checkbox>
                    <div class="table-row-img"></div>
                    <dl class="table-row-content" @click.stop="clickHandle(index, item)">
                        <dt class="flex-row">
                            <i v-if="item.status === 1" class="el-icon-unread color-red"></i>
                            <h2>{{item.title}}</h2>
                            <div>{{item.createTime}}</div>
                        </dt>
                        <dd>
                            <!-- <el-tooltip class="item" effect="light" :content="item.content" placement="top-start"> -->
                                <div class="width100 single-ellipsis">{{item.content}}</div>
                            <!-- </el-tooltip> -->
                        </dd>
                    </dl>
                </div>
            </el-checkbox-group>
        </div>
        <paginationPlus :currentPage.sync="pagination.pageIndex" :pageSize.sync="pagination.pageSize" :total="pagination.total" @callback="pageChangeHandle"></paginationPlus>
        <dialogPlus :visible.sync="dialogData.visible" :title.sync="dialogData.title" width="500px" :model="dialogData.data" :heightFull="false">
            <div class="dialog-conent">{{dialogData.data.content}}</div>
            <div slot="footer">
                <el-button type="primary" @click="dialogCloseHandle">知道了</el-button>
            </div>
        </dialogPlus>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-12 20:00:22
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-03 13:50:57
 * @Description: 个人中心-我的消息
 * @Company: 成都二次元动漫
 */
import { dialogMixin, paginationMixin } from '@/mixins';

export default {
    mixins: [dialogMixin, paginationMixin],
    data() {
        return {
            checkAll: false,
            noData: true,
            isIndeterminate: false,
            checkedList: [],
            tableList: [],
            unreadCount: 0
        };
    },
    created() {
        this.getTableList();
        this.getUnreadCount();
    },
    methods: {
        // 全选操作
        checkAllChangeHandle(type) {
            if (type) this.checkedList = this.tableList.map(item => item.id);
            else this.checkedList = [];
        },
        // 单选操作
        checkedChangeHandle(list) {
            if (this.tableList.length === list.length) {
                this.checkAll = true;
                this.isIndeterminate = false;
            } else if (list.length && this.tableList.length !== list.length) {
                this.checkAll = false;
                this.isIndeterminate = true;
            } else if (list.length === 0) {
                this.checkAll = false;
                this.isIndeterminate = false;
            }
        },
        // 点击事件
        clickHandle(index, row) {
            if (row.status === 1) this.makreadHandle([row.id], false);
            this.changeDialog(true, row.title, 0, { ...row, index });
        },
        // 设置已读
        makreadHandle(list, isAll = false) {
            this.$api('markMessageRead', { status: 2, list, isAll }).then(res => {
                this.getUnreadCount();
                if (isAll) this.getTableList();
                else {
                    this.tableList = this.tableList.map(item => {
                        if (list.includes(item.id)) item.status = 2;
                        return item;
                    });
                }
            });
        },
        // 全部设置为已读
        makAllReadHandle() {
            this.makreadHandle(null, true);
        },
        // 删除
        deleteHandle() {
            if (!this.tableList.length) return;
            if (!this.checkedList.length) {
                this.$message.error('请选择删除项');
                return false;
            }
            this.$utils.confirm('', '确认删除所选项吗？', '删除中...', (action, instance, done) => {
                return this.$api('deletePersonalMessage', { list: this.checkedList }).then(res => {
                    this.getTableList();
                });
            });
        },
        // 查询列表
        getTableList() {
            return this.$api('getPersonalMessageList', { readStatus: 0, timeOrder: 2, ...this.pagination }).then(res => {
                this.$refs.table.scrollTop && (this.$refs.table.scrollTop = 0);
                const { rows = [], pageIndex, pageSize, total } = res.data;
                this.noData = (rows.length !== 0);
                this.tableList = rows;
                this.pagination.pageIndex = pageIndex;
                this.pagination.pageSize = pageSize;
                this.pagination.total = total;
            });
        },
        // 获取未读消息数量
        getUnreadCount() {
            return this.$api('getUnreadCount').then(({ data }) => (this.unreadCount = data.count));
        },
        // 分页
        pageChangeHandle({ page, size }) {
            this.getTableList();
        }
    }
};
</script>

<style lang="scss">
    @import '../../../../styles/var';
    .personal-msg{
        &-head{
            align-items: center;
            h2{
                font-size: 18px;
                margin-right: 10px;
            }
            >div{
                color: $color-info;
            }
            margin-bottom: 20px;
        }
        &-option{

        }
        .dialog-conent{
            padding: 20px;
            word-wrap:break-word;
        }
        &-table{
            flex-grow: 1;
            position: relative;
            overflow-y: auto;
            padding-bottom: 25px;
            .table-row{
                padding: 15px 0;
                align-items: center;
                border-bottom: 1px solid $border-color-base;
                .el-checkbox__label{
                    display: none;
                }
                &-img{
                    width: 45px;
                    height: 45px;
                    overflow: hidden;
                    margin-left: 14px;
                    border: 1px solid $border-color-base;
                    background: #0d4074;
                    background-image: url('/static/images/logo_white2.png');
                    background-repeat: no-repeat;
                    background-size: cover;
                }
                &-content{
                    width: calc(100% - 90px);
                    height: 45px;
                    margin-left: 15px;
                    cursor: pointer;
                    .el-icon-unread{
                        font-size: 14px;
                        margin-right: 5px;
                    }
                    dt, dd{
                        line-height: 24px;
                    }
                    dt{
                        align-items: center;
                        h2{
                            font-size: 14px;
                            color: #606266;
                        }
                        >div{
                            color: $color-info;
                            margin-left: 10px
                        }
                    }
                    dd{
                        width: 100%;
                        font-size: 14px;
                        color: $color-text-regular;
                    }
                }
            }
        }
    }
</style>
